<template>
  <v-simple-table class="listing-table" v-if="listings_count">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="listing-table__heading text-left" width="200">Маркетплейс</th>
          <th class="listing-table__heading text-left">Листинг</th>
          <th class="listing-table__heading text-right">Количество товаров</th>
        </tr>
      </thead>
      <tbody>
        <tr class="listing-table__row" v-for="item in listings" :key="item.id" @click="goToListing(item.id)">
          <td>
            {{ item.marketplace.name }}
          </td>
          <td>
            {{ item.region.name }}
          </td>
          <td class="text-right" v-text="item.items_count" />
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  <div v-else>Кажется тут пусто ;(</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('feeds', ['listings_count', 'listings']),
  },
  methods: {
    goToListing(itemId) {
      this.$router.push(`/feeds/${itemId}/`);
    },
  },
};
</script>

<style lang="scss" scoped>
.listing-table {
  &__heading {
    background-color: hsl(0, 0%, 95%);
  }

  &__row {
    cursor: pointer;
  }
}
</style>
