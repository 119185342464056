<template>
  <div class="feeds">
    <v-col cols="12">
      <div class="feeds__header">
        <div class="feeds__title">
          Листинги
        </div>
        <div class="feeds__stats">
          {{ statsText }}
        </div>
      </div>
    </v-col>
    <v-col class="my-15 text-center" cols="12" v-if="isLoading">
      <v-progress-circular size="42" indeterminate />
    </v-col>
    <v-col cols="12" v-else>
      <FeedTable />
    </v-col>
    <v-row justify="center" v-if="pageCount">
      <v-col cols="3">
        <v-pagination v-model="page" :length="pageCount" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapState, mapActions } from 'vuex';

import PaginationMixin from '@/mixins/PaginationMixin';

import FeedTable from '@/components/FeedTable/FeedTable';

export default {
  components: {
    FeedTable,
  },
  mixins: [PaginationMixin],
  data() {
    return {
      itemsPerPage: 24,
      isLoading: false,
    };
  },
  metaInfo() {
    return {
      title: 'Листинги — Инвентарь',
    };
  },
  computed: {
    ...mapState('feeds', ['listings', 'listings_count']),
    pageCount() {
      if (this.listing_count > this.itemsPerPage) return 0;

      return Math.ceil(this.listing_count / this.itemsPerPage);
    },
    statsText() {
      if (this.isLoading) return null;

      const totalProductsCount = this.listings.reduce((total, current) => total + current.items_count, 0);
      const totalProductsCountFormatted = Intl.NumberFormat('ru-RU').format(totalProductsCount);

      return `${this.listings_count}, товаров: ${totalProductsCountFormatted}`;
    },
  },
  watch: {
    '$route.query': {
      deep: true,
      handler: debounce(async function (params) {
        this.fetchListings(params);
      }, 400),
    },
  },
  created() {
    this.fetchListings();
  },
  methods: {
    ...mapActions('feeds', ['FETCH_LISTINGS']),
    async fetchListings(params = {}) {
      this.isLoading = true;

      try {
        await this.FETCH_LISTINGS({
          ...params,
          page_size: this.itemsPerPage,
        });
      } catch (e) {
        if (!e.isAxiosError) throw e;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.feeds {
  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    margin-right: 16px;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
  }

  &__stats {
    font-size: 12px;
    line-height: 16px;
  }
}
</style>
